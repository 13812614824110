import React, { FormEvent, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Fade,
  Flex,
  FormControl,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useLogin } from "utils/auth";
import asyncVoidHandler from "utils/asyncVoidHandler";
import LoginLayout from "components/Layout/LoginLayout";
import Input from "components/Layout/LoginLayoutInput";
import HeadTitle from "components/Shared/HeadTitle";
import TermsLink from "components/Shared/TermsLink";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { error }, { isLoading, isLoggingIn }] = useLogin();

  const handleSubmit = async (
    e: FormEvent<HTMLDivElement & HTMLFormElement>,
  ) => {
    e.preventDefault();
    await login({ variables: { email, password } });
  };

  return (
    <Box
      as="form"
      onSubmit={asyncVoidHandler(handleSubmit)}
      width="100%"
      mt={2}
    >
      <HeadTitle title="Login" />
      <Fade in={!!error}>
        <Alert status="error" variant="left-accent" mb={10}>
          <AlertIcon />
          <AlertDescription>{error?.message}</AlertDescription>
        </Alert>
      </Fade>

      <FormControl id="email" isRequired>
        <Input
          type="email"
          placeholder="Email"
          autoComplete="username"
          autoFocus
          size="lg"
          backgroundColor="white"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
      </FormControl>

      <FormControl id="current-password" mt={6} isRequired>
        <Input
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          size="lg"
          backgroundColor="white"
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>

      <Button
        type="submit"
        size="lg"
        colorScheme="pinkButton"
        mt={6}
        py={5}
        w="100%"
        disabled={!email || !password || isLoading}
        isLoading={isLoading}
        loadingText={isLoggingIn ? "Logging in" : "Submitting"}
      >
        Submit
      </Button>

      <Text fontSize="xs" color="gray.500" mt={4}>
        By using this service you are agreeing to the <TermsLink />
      </Text>

      <Box textAlign="right" mt={2}>
        <NextLink href="/forgot-password" passHref legacyBehavior>
          <Link
            fontFamily="Gotham"
            fontWeight={200}
            fontSize="sm"
            _hover={{ color: "magenta.dark" }}
          >
            Forgot Password?
          </Link>
        </NextLink>
      </Box>

      <Flex mt={14} alignItems="center" flexWrap="wrap">
        <Heading
          as="h3"
          size="md"
          color="magenta.dark"
          whiteSpace="nowrap"
          mr={5}
          my={2}
        >
          New User?
        </Heading>

        <Button
          as="a"
          href="https://www.rodeocpg.com/contact-us"
          flex="1 1 0"
          variant="outline"
          color="magenta.dark"
          borderRadius="lg"
          py={3}
          px={6}
        >
          Contact Us
        </Button>
      </Flex>
    </Box>
  );
}

Login.getLayout = (page: React.ReactElement) => (
  <LoginLayout heading="Welcome Back">{page}</LoginLayout>
);

Login.allowUnauthenticated = true;
Login.disallowAuthenticated = true;
