import React from "react";
import { Box, Center, Container, Flex, Heading } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  heading?: string;
  subheading?: string;
};

export default function LoginLayout({ children, heading, subheading }: Props) {
  return (
    <Container as={Flex} maxWidth="70rem" flexWrap="wrap" padding={0}>
      <Box as={Center} position="relative" px={[6, 16]} py={16} flex="1 1 50%">
        <Box>
          <Heading
            as="h1"
            size="4xl"
            fontSize={["4xl", "5xl", "6xl"]}
            color="magenta.veryDark"
            lineHeight={1.25}
          >
            {heading}
          </Heading>

          {subheading && (
            <Heading as="h2" fontFamily="Gotham" fontWeight={200} marginTop={4}>
              {subheading}
            </Heading>
          )}
        </Box>
        <Box
          width="185px"
          height="185px"
          borderRadius="50%"
          borderWidth={3}
          borderStyle="solid"
          borderColor="lightPink"
          position="absolute"
          bottom="-5%"
          left="25%"
          zIndex={-1}
        />
        <Box
          backgroundColor="lightCyan"
          height="369px"
          width="369px"
          borderRadius="50%"
          position="absolute"
          left="-25%"
          bottom="-5%"
          zIndex={-2}
        />
      </Box>

      <Center flex="1 1 50%" px={[6, 16]} pt={[6, null, null, "10vh"]} pb={16}>
        {children}
      </Center>

      <Box // placing this with the other shapes in the left box causes scrollbars when boxes are wrapped
        width="215px"
        height="218px"
        background="repeating-linear-gradient(to bottom, #d7ff6e 0, #d7ff6e 4px, transparent 4px, transparent 8px)"
        transform="rotate(-30deg) translateX(-50%)"
        position="absolute"
        left="50%"
        top="-10%"
        zIndex="-1"
      />
    </Container>
  );
}

export const getLayout = (page: React.ReactElement) => (
  <LoginLayout>{page}</LoginLayout>
);
